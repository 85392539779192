import { useContext, useState } from "react";
import axios from "axios";
import { RolesContext } from "../context/RolesContext";

const useFetchRate = (config, authHeaders, clientId, entityId) => {
  const [rate, setRate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [apiResponse, setApiResponse] = useState(null);
  const { roles } = useContext(RolesContext);
  const sameCurrency = !roles.includes("quote");

  // Mock response generator for same currency transactions
  const generateMockResponse = (currency, amountField, amount, fromLedgerId) => ({
    request: {
      clientId: clientId,
      request: {
        entityId: entityId,
        currency: currency,
        [amountField]: amount,
        ledgerId: fromLedgerId,
      }
    },
    response: {
      id: null,
      rate: "1",
      expiry: new Date(Date.now() + 600000).toISOString(), // Expires in 10 minutes
      inverse: "1",
      currency: currency,
      buyAmount: amount,
      sellAmount: amount
    }
  });

  const fetchRate = async (currency, amountField, amount, fromLedgerId, date = null) => {
    setIsLoading(true);
    setError(null);

    try {
      if (sameCurrency) {
        const mockResponse = generateMockResponse(currency, amountField, amount, fromLedgerId);
        setApiResponse(mockResponse);
        setRate("1");
      } else {
        const requestData = {
          clientId: clientId,
          request: {
            entityId: entityId,
            currency: currency,
            [amountField]: amount,
            ledgerId: fromLedgerId,
            date: date
          }
        };

        const response = await axios.post(`${config.API_URL}/figaro/v1/quote`, requestData, { headers: authHeaders });

        if (response.data.error) {
          setError(response.data.error.code);
          throw new Error(response.data.error.code + ": " + (response.data.error.message || "An internal error occurred"));
        }

        setApiResponse({ request: requestData, response: response.data });
        setRate(response.data.rate);
      }
    } catch (err) {
      setError(err);
      console.error("Error fetching FX rates:", err);
    } finally {
      setIsLoading(false);
    }
  };

  // Submit conversion or inject mock response if sameCurrency
  const submitCurrencyConversion = async (values, handleNext, onApiResponse, fromLedgerId, whichInputTouched) => {
    setIsLoading(true);

    try {
      const amountField = whichInputTouched === "sellAmount" ? { sellAmount: values.sellAmount } : { buyAmount: values.buyAmount };

      if (sameCurrency) {
        // Inject mock response without API call
        const mockResponse = generateMockResponse(values.currency, whichInputTouched, amountField[whichInputTouched]);

        onApiResponse(mockResponse);
        handleNext();
      } else {
        const requestData = {
          clientId: clientId,
          request: {
            entityId: entityId,
            currency: values.currency,
            ...amountField,
            ledgerId: fromLedgerId
          }
        };

        const response = await axios.post(
          `${config.API_URL}/figaro/v1/quote`,
          requestData,
          { headers: authHeaders }
        );

        if (response.data.error) {
          console.error("API Error:", response.data.error);
          return;
        }

        const newApiResponse = {
          request: requestData.request,
          response: response.data
        };

        onApiResponse(newApiResponse);
        handleNext();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = "/token-expiry";
      } else {
        console.error("Error fetching new FX rates:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { rate, isLoading, error, fetchRate, submitCurrencyConversion, apiResponse };
};

export default useFetchRate;
