import getFormattedCurrencyAmount from "../../store/getFormattedCurrencyAmount";
import PulsatingDot from "../../ui-component/PulsatingDot";

export const ledgerColumns = [
  {
    field: "state",
    headerName: "",
    flex: 0.2,
    renderCell: (params) => (
      <PulsatingDot status={params.value} />
    ),
  },  { field: "description", headerName: "Account Name", flex: 1 },
  {
    field: "balance", headerName: "Balance", flex: 1,
    valueGetter: (value, row) => {
      if (row) {
        return `${getFormattedCurrencyAmount(row.balance, row.currency)}`;
      }
      return "";
    }
  },
  {
    field: "available", headerName: "Available", flex: 1,
    valueGetter: (value, row) => {
      if (row) {
        return `${getFormattedCurrencyAmount(row.available, row.currency)}`;
      }
      return "";
    }
  }
];
