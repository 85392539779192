// ============================|| Material UI TableGrid Style  ||============================ //
import { styled } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: theme.palette.primary.main,
    fontWeight: "bold",
    color: "#FFF",
    fontSize: "1rem",
    [theme.breakpoints.down("md")]: {
      fontSize: ".75rem"
    }
  },
  "& .MuiDataGrid-sortIcon": {
    color: "#FFF" // white color
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-ptiqhd-MuiSvgIcon-root": {
    color: "#FFF"
  },
  "& .MuiDataGrid-footerContainer": {
    minHeight: "80px",
    padding: 0,
    overflow: "hidden"
  },
  "& .MuiTablePagination-root": {
    overflow: "hidden",
    padding: 0
  },
  "& .MuiTablePagination-toolbar": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden"
  },
  "& .MuiTablePagination-spacer": {
    display: "none"
  }

}));
