import React from "react";
// project imports
import useLedgers from "../../store/hooks/useLedgerDetails";
import { StyledDataGrid } from "../../ui-component/datagrid/StyledDataGrid";
import { ledgerColumns } from "./ledgerColumns";
import { dataGridStyles } from "./ledgerTableStyles";


// ============================|| Get Ledgers Table  ||============================ //
const GetLedgers = () => {
  const { ledgersData, loading, error } = useLedgers();

  return (
    <div style={{ height: 800, width: "100%", display: "flex", flexDirection: "column" }}>
      {loading ? <p>Loading Accounts...</p> :
        error ? <p>{error.message}</p> :
          <StyledDataGrid
            rows={ledgersData}
            columns={ledgerColumns}
            getRowId={(row) => row.ledgerId}
            sx={dataGridStyles}
          />
      }
    </div>
  );
};

GetLedgers.propTypes = {};

export default GetLedgers;