import React, {useContext} from "react";
// 3rd party
import {useNavigate} from "react-router-dom";
// project imports
import MainCard from "../ui-component/cards/MainCard";
import {RolesContext} from "../store/context/RolesContext";
import BankApprovalsDataGrid from "../ui-component/approvals/approvals-table/BankApprovalsDataGrid";
import useFetchApprovalsData from "../store/hooks/useFetchApprovalsData";

//-------------------------------------APPROVALS VIEW --------------------------------------//
const BankApprovals = () => {
    const {
        data,
        fetchData,
        dateFilter,
        isShowDateFilter,
        setIsShowDateFilter
    } = useFetchApprovalsData("/approvals/v1/list_all");
    const {roles} = useContext(RolesContext)
    const navigate = useNavigate();

    if (!(roles.includes("bank::payment") && roles.includes("approve::payment::view"))) {
        navigate("/");
    }

    return <MainCard title={""} content={false}>
        <BankApprovalsDataGrid
            data={data}
            refreshData={fetchData}
            dateFilter={dateFilter}
            isShowDateFilter={isShowDateFilter}
            setIsShowDateFilter={setIsShowDateFilter}
        />
    </MainCard>;
}

export default BankApprovals;
