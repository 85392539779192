import { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuthHeaders } from "../../auth/AuthHeaders";
import ConfigContext from "../context/ConfigContext";
import { getFormFields } from "../../ui-component/beneficiary-form/getVisab2bFormFields";

const useCreateBeneficiary = (setResponse, sendingClient, handleCloseBankModal) => {
  const navigate = useNavigate();
  const config = useContext(ConfigContext);
  const authHeaders = useAuthHeaders();

  return useCallback(
    async (values, resetForm, createAnother = false, localeData) => {
      const apiUrl = config.API_URL;
      const clientId = sendingClient?.value || sessionStorage.getItem("clientId");

      const capitalizedPostalCode = values.postalCode.toUpperCase();
      const currency = values.currency && values.currency.toLowerCase() === "none" ? null : values.currency;
      const formattedPhone = values.phone ? `+${values.phone.replace(/[^0-9]/g, "")}` : undefined;

      const countryCode = values.InfoType === "visab2b" ? values.bic?.substring(4, 6).toUpperCase() || "" : "";
      const countryData = localeData[countryCode] || {};
      const requiredFields = getFormFields(countryData).map(field => field.name) || [];

      const requestData = {
        clientId: clientId,
        totp: values.totp,
        request: {
          entityId: values.entityId,
          details: {
            type: values.type,
            name: values.name,
            ...(requiredFields.includes("contact") && values.contact && { contact: values.contact }),
            ...(requiredFields.includes("phone") && formattedPhone && { phone: formattedPhone }),
            ...(currency && { currency: currency }),
            ...(requiredFields.includes("nationalId") && values.nationalId && { nationalId: values.nationalId }),
            ...(values.shortName && { shortName: values.shortName }),
            ...(requiredFields.includes("agent") && values.agent && { agent: values.agent }),
            ...(requiredFields.includes("lei") && values.lei && { lei: values.lei })
          }
        }
      };
      if (values.InfoType === "ach") {
        requestData.request.details.ach = {
          type: values.achType,
          aba: values.aba,
          account: values.account
        };
      } else if (values.InfoType === "visab2b") {
        requestData.request.details.visab2b = {
          bic: values.bic,
          address: {
            street1: values.street1,
            ...(values.street2 && { street2: values.street2 }),
            city: values.city,
            regionCode: values.state,
            country: values.country,
            ...(values.postalCode && { postalCode: capitalizedPostalCode })
          },
          ...(values.iban && { iban: values.iban }),
          ...(values.account && { account: values.account }),
          ...(requiredFields.includes("branch") && values.branch && { branch: values.branch }),
          ...(requiredFields.includes("bank") && values.bank && { bank: values.bank })
        };
      } else if (values.InfoType === "interLedger") {
        requestData.request.details.interledger = {
          paymentPointer: values.paymentPointer,
          address: {
            street1: values.street1,
            street2: values.street2,
            city: values.city,
            regionCode: values.state,
            country: values.country,
            postalCode: capitalizedPostalCode
          }
        };
      } else if (values.InfoType === "wire") {
        requestData.request.details.wire = {
          iban: values.paymentType === "iban" ? values.iban : undefined,
          account: values.paymentType === "account" ? values.account : undefined,
          address: [values.addressLine1, values.addressLine2, values.addressLine3].filter(line => line),
          bank: {
            name: values.bankName,
            swift: values.swift,
            address: [values.bankAddressLine1, values.bankAddressLine2, values.bankAddressLine3].filter(line => line)
          }
        };
      }

      try {
        const response = await axios.post(`${apiUrl}/figaro/v1/beneficiary`, requestData, { headers: authHeaders });
        setResponse(response.data);
        if (!response.data.error) {
          if (response.data.duplicate) {
            toast.warn("Oh no! Looks like that beneficiary nickname is already used.", {
              position: toast.POSITION.BOTTOM_LEFT,
              theme: "colored"
            });
            return;
          }
          if (createAnother) {
            resetForm();
            toast.success("Success! Beneficiary was added.", {
              position: toast.POSITION.BOTTOM_LEFT,
              theme: "colored"
            });
            return;
          }
          if (sendingClient) {
            toast.success("Success! Beneficiary was added.", {
              position: toast.POSITION.BOTTOM_LEFT,
              theme: "colored"
            });
            handleCloseBankModal();
          } else {
            navigate("/view-beneficiary", {
              state: { fromCreateEntity: true }
            });
          }
        } else {
          switch (response.data.error.code) {
            case "InvalidTOTP":
              toast.error("Oh no! Your verification code didn't work.", {
                position: toast.POSITION.BOTTOM_LEFT,
                theme: "colored"
              });
              break;
            default:
              toast.error("Oh no! Something went wrong please try again", {
                position: toast.POSITION.BOTTOM_LEFT,
                theme: "colored"
              });
          }
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error creating beneficiary.", {
          position: toast.POSITION.BOTTOM_LEFT,
          theme: "colored"
        });
        if (error.response && error.response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/token-expiry";
        }
      }
    },
    [navigate, setResponse]
  );
};

export default useCreateBeneficiary;
