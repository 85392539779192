import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
// material-ui
import { Divider, Grid } from "@mui/material";

// project imports
import Logo from "../ui-component/Logo";
import PasswordLessLogin from "../auth/PasswordLessLogin";
import AuthWrapper from "../ui-component/auth/AuthWrapper";
import AuthCardWrapper from "../ui-component/auth/AuthCardWrapper";
import { getRandomImage } from "../store/getRandomImage";
import ConfigContext from "../store/context/ConfigContext";

// ================================|| Figaro - LOGIN ||================================ //
const LoginScreen = () => {
  const webConfig = useContext(ConfigContext);
  const customLoginImage = webConfig.LOGIN_IMAGE;

  const [backgroundImage, setBackgroundImage] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectTo = searchParams.get("redirect") || "/";

  useEffect(() => {
    if (customLoginImage) {
      setBackgroundImage(customLoginImage);
    } else {
      setBackgroundImage(getRandomImage());
    }
  }, [customLoginImage]);

  return (
    <AuthWrapper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item container justifyContent="center" md={6} lg={7}>
          <AuthCardWrapper>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Link to="#" aria-label="theme-logo">
                  <Logo />
                </Link>
              </Grid>
              <Grid item xs={12}>
                <PasswordLessLogin redirectTo={redirectTo} />
              </Grid>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Divider />
              </Grid>
            </Grid>
          </AuthCardWrapper>
        </Grid>
        <Grid
          item
          md={6}
          lg={5}
          sx={{
            height: "100vh",
            maxHeight: "100vh",
            display: { xs: "none", md: "block" },
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></Grid>
      </Grid>
    </AuthWrapper>
  );
};

export default LoginScreen;
