export const dataGridStyles = {
  backgroundColor: "#ffffff",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  overflow: "hidden",
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9f9f9",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#ffffff",
    },
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "primary.main",
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "700",
      fontSize: "1rem",
    },
  },
};
