import React, { useContext, useEffect, useState, useCallback } from "react";
import { Box, Grid, IconButton, InputAdornment, Tooltip } from "@mui/material";
import CustomFormField from "../../ui-component/form-fields/CustomFormField";
import CustomDropdown from "../../ui-component/form-fields/CustomDropdown";
import { ResponseDisplay } from "../response/ResponseDisplay";
import { CreateBeneficiaryTOTPModal } from "../../ui-component/extended/modal/CreateBeneficiaryTOTPModal";
import { VisaB2BForm } from "../../ui-component/beneficiary-form/Visab2bForm";
import { ACHSchema } from "../../ui-component/beneficiary-form/AchValidationSchema";
import { ACHForm } from "../../ui-component/beneficiary-form/AchForm";
import { InterLedgerForm } from "../../ui-component/beneficiary-form/InterledgerForm";
import { WireForm } from "../../ui-component/beneficiary-form/WireForm";
import { SplitButton } from "../../ui-component/extended/SplitButton";
import useCreateBeneficiary from "../../store/hooks/useCreateBeneficiary";
import ConfigContext from "../../store/context/ConfigContext";
import MainCard from "../../ui-component/cards/MainCard";
import { WireSchema } from "../../ui-component/beneficiary-form/WireValidationSchema";
import { InterLedgerSchema } from "../../ui-component/beneficiary-form/InterledgerValidationSchema";
import { mapInitialEntityValues } from "./mapInitialEntityValues";
import { getVisaB2BSchema } from "../../ui-component/beneficiary-form/Visab2bValidationSchema";
import { Form, Formik } from "formik";
import { IconCopy } from "@tabler/icons-react";
import * as Yup from "yup";
import axios from "axios";
import { useAuthHeaders } from "../../auth/AuthHeaders";

/// ============================|| Default - Validation ||============================ //
export const DefaultSchema = Yup.object().shape({
  InfoType: Yup.string().required("Network Type is required")
});

/// ============================||  Base Form - Create Beneficiary ||============================ //
const CreateEntityForm = ({ sendingClient, handleCloseBankModal, initialValues }) => {
  const determineSchema = infoType => {
    switch (infoType) {
      case "ach":
        return ACHSchema;
      case "visab2b":
        return getVisaB2BSchema();
      case "interLedger":
        return InterLedgerSchema;
      case "wire":
        return WireSchema;
      default:
        return DefaultSchema;
    }
  };

  const config = useContext(ConfigContext);
  const authHeaders = useAuthHeaders();

  const [selectedOption, setSelectedOption] = useState("");
  const [response, setResponse] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const infoTypes = config.BENEFICIARY_TYPE || [];
  const defaultInfoType = infoTypes.length > 0 ? infoTypes[0].value : "";
  const [currentPaymentSystem, setCurrentPaymentSystem] = useState(defaultInfoType);
  const [currentSchema, setCurrentSchema] = useState(
    determineSchema(mapInitialEntityValues(initialValues, defaultInfoType).InfoType)
  );

  const [localeData, setLocaleData] = useState({});
  const [ibanSupportedCountries, setIbanSupportedCountries] = useState(new Set());

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCreateBeneficiary = useCreateBeneficiary(setResponse, sendingClient, handleCloseBankModal);
  const createAnother = selectedOption === "Submit and Create Another";

  // Fetch locale data
  const fetchLocaleData = useCallback(async () => {
    try {
      const response = await axios.post(
        `${config.API_URL}/locale/v1/get`,
        {},
        {
          headers: authHeaders
        }
      );
      const localeData = response.data;
      const ibanSupportedCountries = new Set(
        Object.entries(localeData)
          .filter(([_, data]) => data.iban)
          .map(([countryCode, data]) => ({
            country: countryCode,
            ...data.iban
          }))
      );
      setLocaleData(localeData);
      setIbanSupportedCountries(ibanSupportedCountries);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, []);

  const handleCopyNameToEntityId = (name, setFieldValue) => {
    setFieldValue("entityId", name);
  };

  const handleSchemaUpdate = newSchema => {
    setCurrentSchema(newSchema);
  };

  useEffect(() => {
    if (initialValues) {
      const mappedValues = mapInitialEntityValues(initialValues, defaultInfoType);
      setCurrentSchema(determineSchema(mappedValues.InfoType));
    }
  }, [initialValues]);

  useEffect(() => {
    // only retrieve localeData for visab2b and has not already loaded
    if (currentPaymentSystem === "visab2b" && !Object.keys(localeData).length) {
      fetchLocaleData();
    }
  }, [currentPaymentSystem]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <MainCard title={"Create A New Beneficiary"}>
      <Box sx={{ overflow: "auto" }}>
        <Formik
          initialValues={mapInitialEntityValues(initialValues, defaultInfoType)}
          enableReinitialize={true}
          validationSchema={currentSchema}
          onSubmit={(values, { resetForm }) => {
            handleCreateBeneficiary(values, resetForm, createAnother, localeData);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            touched,
            errors,
            dirty,
            isValid,
            resetForm,
            setErrors,
            validateForm
          }) => (
            <Form>
              {infoTypes.length > 1 ? (
                <Grid item xs={8} sx={{ mb: 1 }}>
                  <CustomDropdown
                    name="InfoType"
                    label="Payment System"
                    value={values.InfoType}
                    handleChange={e => {
                      handleChange(e);
                      if (e.target.name === "InfoType") {
                        setCurrentSchema(determineSchema(e.target.value));
                        setFieldValue("InfoType", e.target.value);
                        setCurrentPaymentSystem(e.target.value);
                      }
                    }}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={infoTypes.map(infoType => ({
                      value: infoType.value,
                      label: infoType.label
                    }))}
                  />
                </Grid>
              ) : (
                <input type="hidden" name="InfoType" value={defaultInfoType} />
              )}
              <Grid container spacing={1}>
                <Grid item xs={12} lg={8}>
                  <CustomDropdown
                    name="type"
                    label="Beneficiary Category "
                    value={values.type}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    options={[
                      { value: "person", label: "Person" },
                      { value: "company", label: "Company" }
                    ]}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomFormField
                    name="name"
                    label="Beneficiary Name"
                    value={values.name}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    validateChars={true}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <CustomFormField
                    name="entityId"
                    label="Beneficiary Nickname (Reference)"
                    value={values.entityId}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    endAdornment={
                      values.name && (
                        <InputAdornment position="end">
                          <Tooltip title="Copy Beneficiary Name">
                            <IconButton onClick={() => handleCopyNameToEntityId(values.name, setFieldValue)}>
                              <IconCopy />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }
                  />
                </Grid>
                {values.InfoType === "ach" && (
                  <Grid item xs={12}>
                    <ACHForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                )}
                {values.InfoType === "visab2b" && (
                  <Grid item xs={12}>
                    <VisaB2BForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      onSchemaUpdate={handleSchemaUpdate}
                      validateForm={validateForm}
                      localeData={localeData}
                      ibanSupportedCountries={ibanSupportedCountries}
                    />
                  </Grid>
                )}
                {values.InfoType === "interLedger" && (
                  <Grid item xs={12}>
                    <InterLedgerForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                )}
                {values.InfoType === "wire" && (
                  <Grid item xs={12}>
                    <WireForm
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setErrors={setErrors}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={4}>
                  <SplitButton
                    isDisabled={!isValid || !dirty}
                    options={[
                      {
                        label: "Submit",
                        action: () => {
                          setSelectedOption("Submit");
                          setIsModalOpen(true);
                        }
                      },
                      {
                        label: "Submit and Create Another",
                        action: () => {
                          setSelectedOption("Submit and Create Another");
                          setIsModalOpen(true);
                        }
                      }
                    ]}
                  />

                  <CreateBeneficiaryTOTPModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    onConfirm={updatedValues => {
                      handleCreateBeneficiary(updatedValues, resetForm, createAnother, localeData);
                    }}
                    values={values}
                    localeData={localeData}
                  />
                </Grid>
                {response && (
                  <Grid item xs={12} sm={8}>
                    <Box>
                      <ResponseDisplay response={response} typeOfRequest={"Beneficiary Creation"} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </MainCard>
  );
};

export default CreateEntityForm;
