import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import axios from "axios";
import { Box, Button, Grid, Typography } from "@mui/material";
import OtpInput from "react18-input-otp";
import { useAuthHeaders } from "../auth/AuthHeaders";
import ConfigContext from "../store/context/ConfigContext";
import { useClientAccount } from "../store/context/ClientAccountContext";
import LedgerDropdown from "../ui-component/ledger/LedgerDropdown";
import CustomCurrencyValueInput from "../ui-component/form-fields/CustomCurrencyValueInput";
import CustomFormField from "../ui-component/form-fields/CustomFormField";
import dayjs from "dayjs";
import { getCurrentFormattedDate } from "../store/dateFormatter";
import CreditResponse from "./response/CreditResponse";

const CreateTransfer = () => {
  const responseRef = useRef(null);
  const [response, setResponse] = useState(null);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [fromCurrency, setFromCurrency] = useState("");
  const [showTotpInput, setShowTotpInput] = useState(false);
  const [localTotp, setLocalTotp] = useState("");
  const authHeaders = useAuthHeaders();
  const config = React.useContext(ConfigContext);
  const { clientId } = useClientAccount();

  const handleCreateTransferSubmit = async (values, { setSubmitting }) => {
    if (!showTotpInput) {
      setShowTotpInput(true);
      setSubmitting(false);
      return;
    }

    try {
      const options = {
        method: "POST",
        url: `${config.API_URL}/figaro/v1/transfer`,
        headers: authHeaders,
        data: {
          clientId: clientId,
          totp: localTotp,
          request: {
            currency: fromCurrency,
            amount: values.amount,
            description: values.description,
            reference: values.reference,
            fromLedgerId: values.fromLedgerId,
            toLedgerId: values.toLedgerId,
            date: values.settlementDate,
          },
        },
      };
      const response = await axios.request(options);
      setResponse(response.data);
      setResponseModalOpen(true); // Open the response modal
      setShowTotpInput(false);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const clearResponse = () => {
    setResponse(null);
    setResponseModalOpen(false);
  };

  return (
    <Box>
      <Formik
        initialValues={{
          amount: "",
          description: "",
          reference: `Transfer - ${getCurrentFormattedDate()}`,
          fromLedgerId: "",
          toLedgerId: "",
          settlementDate: dayjs().toDate(),
        }}
        validationSchema={Yup.object().shape({
          amount: Yup.number()
            .required("Amount is required")
            .positive("Amount must be greater than 0"),
          description: Yup.string().required("Description is required"),
          reference: Yup.string().required("Reference is required"),
          fromLedgerId: Yup.string().required("From Account is required"),
          toLedgerId: Yup.string().required("To Account is required"),
        })}
        onSubmit={async (values, formikHelpers) => {
          await handleCreateTransferSubmit(values, formikHelpers);
        }}
      >
        {({
            values,
            setFieldValue,
            touched,
            errors,
            handleSubmit,
            isSubmitting,
          }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LedgerDropdown
                  name="fromLedgerId"
                  label="From Account"
                  setSelectedCurrency={(currency) => {
                    setFromCurrency(currency);
                    setFieldValue("toLedgerId", "");
                  }}
                  defaultCurrency="USD"
                  textSize="1rem"
                  externalOnChange={(event) => {
                    setFieldValue("fromLedgerId", event.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <LedgerDropdown
                  name="toLedgerId"
                  label="To Account"
                  textSize="1rem"
                  externalOnChange={(event) => {
                    setFieldValue("toLedgerId", event.target.value);
                  }}
                  filterActiveLedgers={(ledgers) =>
                    ledgers.filter(
                      (ledger) =>
                        ledger.currency === fromCurrency && ledger.ledgerId !== values.fromLedgerId
                    )
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <CustomCurrencyValueInput
                  name="amount"
                  label={`${fromCurrency || "Currency"} Amount`}
                  value={values.amount}
                  handleChange={(event) => setFieldValue("amount", event.target.value)}
                  touched={touched}
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomFormField
                  name="reference"
                  label="Reference"
                  value={values.reference}
                  handleChange={(event) => setFieldValue("reference", event.target.value)}
                  touched={touched}
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12}>
                <CustomFormField
                  name="description"
                  label="Description"
                  value={values.description}
                  handleChange={(event) => setFieldValue("description", event.target.value)}
                  touched={touched}
                  errors={errors}
                />
              </Grid>

              {showTotpInput && (
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ mb: 2 }}>
                    Enter 6-digit authentication code:
                  </Typography>
                  <OtpInput
                    value={localTotp}
                    onChange={setLocalTotp}
                    numInputs={6}
                    containerStyle={{ justifyContent: "space-between" }}
                    inputStyle={{
                      width: "100%",
                      margin: "8px",
                      padding: "10px",
                      border: `1px solid `,
                      borderRadius: 4,
                      ":hover": {
                        borderColor: "#000",
                      },
                    }}
                    focusStyle={{
                      outline: "none",
                      border: `2px solid #000`,
                    }}
                    shouldAutoFocus={true}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {showTotpInput ? "Submit Transaction" : "Confirm Transaction"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {response && (
        <Box mt={2} ref={responseRef}>
          <CreditResponse
            response={response}
            open={responseModalOpen}
            clearResponse={clearResponse}
            isTransfer={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default CreateTransfer;
