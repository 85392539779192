import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

// material-ui
import { GridFooterContainer, GridPagination } from "@mui/x-data-grid";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";

// project imports
import TransactionSummary from "../ui-component/transaction-summary/TransactionSummary";
import { RefreshQueryWithDatePicker } from "../ui-component/datagrid/RefreshQueryToolbar";
import ConfigContext from "../store/context/ConfigContext";
import { transactionHistoryColumns } from "../ui-component/datagrid/transactionHistoryColumns";
import NonFormikLedgerDropDown from "../ui-component/ledger/NonFormikLedgerDropDown";
import { useClientAccount } from "../store/context/ClientAccountContext";
import { useAuthHeaders } from "../auth/AuthHeaders";
import useTransactionHistoryData from "../store/hooks/useTransactionHistoryData";

// third-party
import axios from "axios";
import { CustomNoRowsOverlay } from "../ui-component/datagrid/CustomEmptyDataTable";
import { StyledDataGrid } from "../ui-component/datagrid/StyledDataGrid";
import dayjs from "dayjs";

// ============================|| Transaction History Page  ||============================ //
const Transactions = ({ compactView = false, activeLedgers, ledgerLoading, error }) => {
  const [selectedLedgerId, setSelectedLedgerId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({}); // For storing the column visibility model
  const [isLoading, setIsLoading] = useState(false);

  const authHeaders = useAuthHeaders();
  const config = useContext(ConfigContext);
  const { clientId } = useClientAccount();
  const { data, fetchData } = useTransactionHistoryData("/figaro/v1/history");

  const endOfDay = dayjs().endOf("day");
  const threeDaysAgo = dayjs().subtract(3, "day").startOf("day");

  const [startDate, setStartDate] = useState(dayjs(threeDaysAgo));
  const [endDate, setEndDate] = useState(endOfDay);
  const [showDatePickers, setShowDatePickers] = useState(false);

  // Load saved column visibility preferences from localStorage when the component mounts
  useEffect(() => {
    const savedVisibilityModel = localStorage.getItem("columnVisibilityModel");
    if (savedVisibilityModel) {
      setColumnVisibilityModel(JSON.parse(savedVisibilityModel));
    }
  }, []);

  // Save column visibility preferences to localStorage whenever they change
  useEffect(() => {
    if (Object.keys(columnVisibilityModel).length > 0) {
      localStorage.setItem("columnVisibilityModel", JSON.stringify(columnVisibilityModel));
    }
  }, [columnVisibilityModel]);

  // Fetch transaction history when refreshFlag, selectedLedgerId, startDate, or endDate changes
  useEffect(() => {  
    const fetchDataHook = async () => {
      setIsLoading(true);
      try {
        await fetchData(startDate, endDate, selectedLedgerId);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchDataHook();
  }, [refreshFlag, selectedLedgerId, startDate, endDate]);

  const handleRowClickForTransactionDetailModal = async reference => {
    try {
      const response = await axios.post(
        `${config.API_URL}/figaro/v1/payment`,
        {
          clientId: clientId,
          request: {
            creditReference: reference
          }
        },
        {
          headers: authHeaders
        }
      );

      if (response.data.error) {
        console.error("Error fetching transaction details:", response.data.error.message);
        toast.error("Unable to fetch transaction details", {
          position: toast.POSITION.BOTTOM_LEFT,
          theme: "colored"
        });
      } else {
        setSelectedTransaction(response.data);
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching transaction details:", error);
    }
  };

  const handleRefresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  // Update the column visibility model whenever the user changes column visibility
  const handleColumnVisibilityChange = newModel => {
    setColumnVisibilityModel(newModel);
  };

  return (
    <div
      style={{
        height: compactView ? "400px" : "75vh",
        width: "100%",
        marginTop: "2vh"
      }}
    >
      <Grid container spacing={2} mb={2}>
        {!compactView && (
          <>
            <Grid item xs={8}>
              <Typography variant={"h2"} sx={{ mb: 3 }}>
                Transaction History
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <NonFormikLedgerDropDown
                ledgerData={activeLedgers}
                loading={ledgerLoading}
                error={error}
                selectedLedgerId={selectedLedgerId}
                setSelectedLedgerId={setSelectedLedgerId}
              />
            </Grid>
          </>
        )}
      </Grid>
      <StyledDataGrid
        rows={data}
        columns={transactionHistoryColumns}
        onRowClick={param => {
          handleRowClickForTransactionDetailModal(param.row.reference);
        }}
        columnVisibilityModel={columnVisibilityModel} // Use the visibility model from state
        onColumnVisibilityModelChange={handleColumnVisibilityChange} // Save visibility changes
        slots={{
          toolbar: compactView
            ? null
            : () => (
                <RefreshQueryWithDatePicker
                  onRefresh={handleRefresh}
                  data={data}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  setShowDatePickers={setShowDatePickers}
                  showDatePickers={showDatePickers}
                />
              ),
          noRowsOverlay: CustomNoRowsOverlay,
          footer: compactView
            ? () => (
                <GridFooterContainer mr={5} p={2}>
                  <Grid item xs={4}>
                    <NonFormikLedgerDropDown
                      ledgerData={activeLedgers}
                      loading={ledgerLoading}
                      error={error}
                      selectedLedgerId={selectedLedgerId}
                      setSelectedLedgerId={setSelectedLedgerId}
                    />
                  </Grid>
                  <Grid item xs={8} textAlign="right">
                    <GridPagination />
                  </Grid>
                </GridFooterContainer>
              )
            : () => (
                <GridFooterContainer>
                  <GridPagination />
                </GridFooterContainer>
              )
        }}
        sx={{
          boxShadow: 5,
          border: 2,
          borderColor: "primary.light",
          backgroundColor: "#fff",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main"
          }
        }}
        loading={isLoading}
        pageSizeOptions={compactView ? [5] : [5, 10, 25]}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibilityModel || { type: false } // Use saved visibility model if exists
          },
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }]
          },
          pagination: {
            paginationModel: {
              pageSize: compactView ? 5 : 25
            }
          }
        }}
      />
      <Dialog open={isModalOpen} onClose={() => setModalOpen(false)} fullWidth maxWidth={"md"}>
        <DialogContent>
          <TransactionSummary selectedTransaction={selectedTransaction} refetchTransactions={handleRefresh} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="secondary" variant={"contained"}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Transactions;
