import {IconCircleCheck, IconCircleX, IconMinus, IconPlus, IconDots} from '@tabler/icons-react';
import {IconButton, Tooltip} from "@mui/material";
import {useContext} from "react";
import {RolesContext} from "../../store/context/RolesContext";
import {formatDateToMonthDayYearWithTime} from "../../common/getDate";


// ============================ || Custom Dot Component || ============================ //
const Dot = ({color}) => (
    <div style={{
        height: '10px',
        width: '10px',
        backgroundColor: color,
        borderRadius: '50%',
        display: 'inline-block',
    }}/>
);

// ============================ || USER ROLES APPROVAL ICON  || ============================ //
const ApprovalIcon = ({approved}) => (
    approved ? (
        <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <IconCircleCheck style={{color: 'green'}}/>
        </div>
    ) : (
        <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <IconCircleX style={{color: 'red'}}/>
        </div>
    )
);

// ============================ || USER ROLES GROUPING COLUMNS  || ============================ //
export const columnGroupingModel = [
    {
        groupId: 'Payment',
        children: [
            { field: 'payment_view' },
            { field: 'quote' },
            { field: 'payment_create' },
            { field: 'payment_cancel' },
            { field: 'approve_payment_view' },
            { field: 'approve_payment' },
        ],
        headerAlign: 'center',

    },
    {
        groupId: 'Beneficiary',
        children: [
            { field: 'beneficiary_create' },
            { field: 'beneficiary_view' },
            { field: 'beneficiary_disable' },
        ],
        headerAlign: 'center',
    },
    {
        groupId: 'User',
        children: [
            { field: 'invite' },
            { field: 'grant' },
            { field: 'revoke' },
            { field: 'users_view' },
        ],
        headerAlign: 'center',
    },
];
// ============================ || USER ROLES TABLE COLUMNS  || ============================ //
export const RolesColumns = (onEditClick, onRevokeClick, onSettingsClick) => {
    const {roles} = useContext(RolesContext);

    const columns = [
        {
            field: 'username',
            headerName: 'User',
            flex: 1.75,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                  {params.row.totp_enabled ? (
                    <Tooltip title="Enrolled">
                        <IconButton>
                            <Dot color="green"/>
                        </IconButton>
                    </Tooltip>
                  ) : params.row.enrollment_expiry ? (
                    <Tooltip title={`Expiry: ${formatDateToMonthDayYearWithTime(params.row.enrollment_expiry)}`}>
                        <IconButton>
                            <Dot color="yellow"/>
                        </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Not Enrolled">
                        <IconButton>
                            <Dot color="red"/>
                        </IconButton>
                    </Tooltip>
                  )}
                  <div style={{marginLeft: '5px', cursor: 'default'}}>{params.value}</div>
                  {(roles.includes("invite") || roles.includes("revoke")) &&
                    <Tooltip title="Manage User">
                        <IconButton style={{width: '30px', height: '30px', marginLeft: '5px'}}>
                            <IconDots onClick={() => onSettingsClick(params.row)}/>
                        </IconButton>
                    </Tooltip>
                  }
              </div>
            )
        },
        // Payment group columns
        {
            field: 'payment_view',
            headerName: 'View',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.payment_view}/>
            ),
        },
        {
            field: 'quote',
            headerName: 'Quote',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.quote}/>
            ),
        },
        {
            field: 'payment_create',
            headerName: 'Create',
            disableColumnMenu: true,
            headerAlign: 'center',
            flex: .5,
            align: 'center',
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.payment_create}/>
            ),
        },
        {
            field: 'payment_cancel',
            headerName: 'Cancel',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.payment_cancel}/>
            ),
        },
        {
            field: 'approve_payment_view',
            headerName: 'View Approvals',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.approve_payment_view}/>
            ),
        },
        {
            field: 'approve_payment',
            headerName: 'Approve',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.approve_payment}/>
            ),
        },
        // Beneficiary group columns
        {
            field: 'beneficiary_view',
            headerName: 'View',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.beneficiary_view}/>
            ),
        },
        {
            field: 'beneficiary_create',
            headerName: 'Create',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.beneficiary_create}/>
            ),
        },
        {
            field: 'beneficiary_disable',
            headerName: 'Archive',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.beneficiary_disable}/>
            ),
        },
        // User group columns
        {
            field: 'users_view',
            headerName: 'View',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.users_view}/>
            ),
        },
        {
            field: 'invite',
            headerName: 'Invite',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.invite}/>
            ),
        },
        {
            field: 'grant',
            headerName: 'Grant Permission',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.grant}/>
            ),
        },
        {
            field: 'revoke',
            headerName: 'Revoke Permission',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .5,
            renderCell: (params) => (
              <ApprovalIcon approved={params.row.revoke}/>
            ),
        }
    ];

    if (roles.includes("grant") || roles.includes("revoke")) {
        const actionsColumn = {
            field: 'actions',
            headerName: 'Manage',
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            flex: .75,
            sortable: false,
            renderCell: (params) => (
              <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                  <Tooltip title={"Grant User Roles"}>
                      <IconButton
                        color={roles.includes("grant") ? "primary" : "default"}
                        disabled={!roles.includes("grant")}
                        sx={{
                            borderColor: 'green',
                            '&:hover': {background: roles.includes("grant") ? '#7eec39' : 'none'},
                        }}
                        aria-label="grant"
                        onClick={() => roles.includes("grant") && onEditClick(params.row)}
                      >
                          <IconPlus/>
                      </IconButton>
                  </Tooltip>
                  <Tooltip title={"Revoke User Roles"}>
                      <IconButton
                        color={roles.includes("revoke") ? "primary" : "default"}
                        disabled={!roles.includes("revoke")}
                        sx={{
                            borderColor: '#FF5722',
                            '&:hover': {background: roles.includes("revoke") ? '#FFCCBC' : 'none'},
                        }}
                        aria-label="revoke"
                        onClick={() => roles.includes("revoke") && onRevokeClick(params.row)}
                      >
                          <IconMinus/>
                      </IconButton>
                  </Tooltip>
              </div>
            )
        };

        columns.push(actionsColumn);
    }

    return columns;
};