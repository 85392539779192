import React, { forwardRef, useState } from "react";
import { Box, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { getDecimalPlaces, shouldFixDecimalScale } from "../../common/currencyDecimalFormater";
import { currencyOptions } from "../../common/CurrencyOptions";

interface LedgerOption {
  ledgerId: string;
  currency: string;
  label: string;
}

interface LedgerInputFieldProps {
  name: string;
  value: string | number;
  placeholder?: string;
  onValueChange: (values: any) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  selectedLedgerId: string;
  ledgerOptions: LedgerOption[];
  onLedgerChange: (ledgerId: string) => void;
  selectDisabled?: boolean;
  selectStyle?: React.CSSProperties;
  textFieldStyle?: React.CSSProperties;
}

const LedgerInputField = forwardRef<HTMLInputElement, LedgerInputFieldProps>(
  (
    {
      name,
      value,
      placeholder,
      onValueChange,
      onBlur,
      onKeyDown,
      onFocus,
      disabled = false,
      selectedLedgerId,
      ledgerOptions,
      onLedgerChange,
      selectDisabled = false,
      selectStyle,
      textFieldStyle,
    },
    ref
  ) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const selectedLedger = ledgerOptions.find((opt) => opt.ledgerId === selectedLedgerId);

    return (
      <NumericFormat
        fullWidth
        variant="outlined"
        name={name}
        value={value}
        placeholder={placeholder}
        onValueChange={onValueChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        thousandSeparator
        decimalScale={getDecimalPlaces(selectedLedger?.currency || "USD")}
        fixedDecimalScale={shouldFixDecimalScale(selectedLedger?.currency || "USD")}
        customInput={TextField}
        inputRef={ref}
        sx={{
          ...textFieldStyle,
          "& .MuiInputBase-input::placeholder": {
            fontSize: "1.2rem",
            opacity: 0.8,
          },
        }}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Select
                value={selectedLedgerId}
                onChange={(e) => onLedgerChange(e.target.value)}
                displayEmpty
                sx={selectStyle}
                disabled={selectDisabled}
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)}
                // ⬇️ Show only the currency when closed, full label when open
                renderValue={(selected) => {
                  const option = ledgerOptions.find((opt) => opt.ledgerId === selected);
                  const currencyData = currencyOptions.find((c) => c.value === option?.currency);

                  return (
                    <Box display="flex" alignItems="center">
                      {currencyData?.icon && (
                        <img
                          src={currencyData.icon}
                          alt={currencyData.label}
                          style={{ marginRight: "10px", height: "30px" }}
                        />
                      )}
                      {option?.currency}
                    </Box>
                  );
                }}
              >
                {ledgerOptions.map((option) => {
                  const currencyData = currencyOptions.find((c) => c.value === option.currency);

                  return (
                    <MenuItem key={option.ledgerId} value={option.ledgerId}>
                      <Box display="flex" alignItems="center">
                        {currencyData?.icon && (
                          <img
                            src={currencyData.icon}
                            alt={currencyData.label}
                            style={{ marginRight: "10px", height: "30px" }}
                          />
                        )}
                        {menuOpen ? `${option.currency} - ${option.label}` : option.currency}
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default LedgerInputField;
