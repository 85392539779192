import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Step, StepLabel, Stepper, Tooltip } from "@mui/material";
import BeneficiaryStepper from "../ui-component/payment-stepper/BeneficiaryStepper";
import MainCard from "../ui-component/cards/MainCard";
import CreateUnknownCurrencyCredit from "./credit-request/CreateUnknownCurrencyCredit";
import CurrencyConverter from "../ui-component/payment-stepper/CurrencyConverter";
import { styled } from "@mui/system";
import CutoffModal from "../ui-component/extended/modal/CutoffModal";
import { RolesContext } from "../store/context/RolesContext";
import { useNavigate } from "react-router-dom";
import {
  IconUserSquareRounded,
  IconCash,
  IconChecks,
} from "@tabler/icons-react";
import CreateUSDCredit from "./credit-request/CreateUSDCredit";
import useLedgers from "../store/hooks/useLedgerDetails";
import HelpDrawer from "../ui-component/HelpDrawer";
import ConfigContext from "../store/context/ConfigContext";

const StepIconWrapper = styled("div")(({ theme, active }) => ({
  ".MuiSvgIcon-root": {
    fontSize: "2rem",
    transition: "color 0.3s",
    color: active ? theme.palette.primary.main : theme.palette.action.disabled,
  },
}));

// ============================|| Make  - Payment ||============================ //
const MakePayment = () => {
  const config = useContext(ConfigContext);
  const { activeLedgers } = useLedgers();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState({});
  const [fxDealDetails, setFxDealDetails] = useState({});
  const [cutoffMissed, setCutoffMissed] = useState(false);
  const [showCutoffModal, setShowCutoffModal] = useState(false);
  const [fxOpenTime, setFxOpenTime] = useState("");
  const [whichInputTouched, setWhichInputTouched] = useState(null);

  const initialQuoteAmount = config.INITIAL_QUOTE_AMOUNT ?? "";

  const { roles } = useContext(RolesContext);
  const navigate = useNavigate();

  if (!roles.includes("payment_create")) {
    navigate("/");
  }

  useEffect(() => {
    const openTimestampString = sessionStorage.getItem("openTimestamp");
    const closeTimestampString = sessionStorage.getItem("closeTimestamp");

    if (!openTimestampString || !closeTimestampString) {
      return;
    }

    const openTimestamp = new Date(JSON.parse(openTimestampString));
    const closeTimestamp = new Date(JSON.parse(closeTimestampString));
    const now = new Date();

    const spansToNextDay = openTimestamp > closeTimestamp;

    let isOpen;
    if (!spansToNextDay) {
      isOpen = now >= openTimestamp && now < closeTimestamp;
    } else {
      if (now < closeTimestamp) {
        isOpen = true;
      } else if (now < openTimestamp) {
        isOpen = false;
      } else {
        isOpen = true;
      }
    }

    setShowCutoffModal(!isOpen);
    setCutoffMissed(!isOpen);

    const nextSignificantTime = isOpen ? closeTimestamp : openTimestamp;
    setFxOpenTime(
      `${nextSignificantTime.toLocaleDateString()} ${nextSignificantTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`,
    );
  }, []);

  const handleBeneficiarySelection = (beneficiaryValue) => {
    setSelectedBeneficiary(beneficiaryValue);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedBeneficiary({});
  };

  const StepIcons = [
    { icon: <IconUserSquareRounded />, label: "Choose Your Beneficiary" },
    { icon: <IconCash />, label: "Create Transaction" },
    { icon: <IconChecks />, label: "Confirmation" },
  ];

  const NetworkTypeConfig = (network) => {
    switch (network) {
      case "wire":
      case "ach":
        return <CreateUSDCredit
          handleBack={handleBack}
          entityDetails={selectedBeneficiary}
          transactionType={selectedBeneficiary.network}
        />
      default:
        return <Box>
          {/*This needs to be refactored to just pass beneficiary in*/}
          <CurrencyConverter
            handleBack={handleBack}
            handleNext={handleNext}
            EntityId={selectedBeneficiary.value}
            entityName={selectedBeneficiary.label}
            whichInputTouched={whichInputTouched}
            setWhichInputTouched={setWhichInputTouched}
            onApiResponse={(apiResponse) => setFxDealDetails(apiResponse)}
            ledgersData={activeLedgers}
            currency={selectedBeneficiary.currency}
            fxDealDetails={fxDealDetails}
            initialQuoteAmount={initialQuoteAmount}
          />
        </Box>
    }
  }

  return (
    <Box mt={2}>
      <MainCard content>
        <Stepper activeStep={activeStep} alternativeLabel>
          {StepIcons.map((step, index) => (
            <Step key={index}>
              <StepLabel
                icon={
                  <Tooltip title={step.label} placement="bottom">
                    <StepIconWrapper active={activeStep === index}>
                      {step.icon}
                    </StepIconWrapper>
                  </Tooltip>
                }
              >
                {""} {/* Hide default step number */}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <BeneficiaryStepper
            handleNext={handleNext}
            handleBeneficiarySelection={handleBeneficiarySelection}
            disableNext={cutoffMissed & !showCutoffModal}
          />
        )}

        {activeStep === 1 && NetworkTypeConfig(selectedBeneficiary.network)}
        {activeStep === 2 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <CreateUnknownCurrencyCredit
              EntityId={selectedBeneficiary.value}
              entityName={selectedBeneficiary.label}
              onBack={handleBack}
              fxDealDetails={fxDealDetails}
              whichInputTouched={whichInputTouched}
            />
            <Button
              onClick={() => setActiveStep(1)}
              variant="contained"
              color="secondary"
              sx={{ px: 4.5 }}
            >
              Back
            </Button>
          </Box>
        )}
        <CutoffModal
          open={showCutoffModal}
          cutOffTime={fxOpenTime}
          handleClose={() => setShowCutoffModal(false)}
        />
      </MainCard>
      <HelpDrawer slug={"create-payment"} />
    </Box>
  );
};

export default MakePayment;
