import { useField } from "formik";
import useLedgers from "../../store/hooks/useLedgerDetails";
import PropTypes from "prop-types";
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect } from "react";
import getFormattedCurrencyAmount from "../../store/getFormattedCurrencyAmount";
import { getLedgerDisplayText } from "../../store/getLedgerDisplay";

const LedgerDropdown = ({
                          name,
                          maxHeight,
                          textSize,
                          label,
                          setSelectedCurrency,
                          setSelectedLedger,
                          filterActiveLedgers,
                          externalOnChange,
                          defaultCurrency,
                          ...props
                        }) => {
  const [field, meta, helpers] = useField(name);
  const isError = Boolean(meta.touched && meta.error);
  const { activeLedgers, loading, error } = useLedgers();

  // Filter ledgers based on the provided filter function
  const filteredLedgers = filterActiveLedgers ? filterActiveLedgers(activeLedgers) : activeLedgers;

  useEffect(() => {
    if (defaultCurrency && !field.value && filteredLedgers.length > 0) {
      const ledgerWithCurrency = filteredLedgers.find((ledger) => ledger.currency === defaultCurrency);
      if (ledgerWithCurrency) {
        helpers.setValue(ledgerWithCurrency.ledgerId);
        if (setSelectedCurrency) {
          setSelectedCurrency(ledgerWithCurrency.currency);
        }
        if (setSelectedLedger) {
          setSelectedLedger(ledgerWithCurrency);
        }
      }
    }
  }, [defaultCurrency, field.value, helpers, filteredLedgers, setSelectedCurrency, setSelectedLedger]);

  const handleChange = (event) => {
    helpers.setValue(event.target.value);
    const selectedLedger = filteredLedgers.find((ledger) => ledger.ledgerId === event.target.value);
    if (selectedLedger) {
      if (setSelectedCurrency) {
        setSelectedCurrency(selectedLedger.currency);
      }
      if (setSelectedLedger) {
        setSelectedLedger(selectedLedger);
      }
    }
    if (externalOnChange) {
      externalOnChange(event);
    }
  };

  return (
    <FormControl fullWidth error={isError} variant="outlined">
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        label={label}
        id={`${name}-select`}
        value={field.value}
        onChange={handleChange}
        variant="outlined"
        MenuProps={{
          style: {
            maxHeight: maxHeight,
          },
        }}
        renderValue={(selectedValue) => {
          const selectedLedger = filteredLedgers.find((ledger) => ledger.ledgerId === selectedValue);
          if (!selectedLedger) return null;

          const displayText = getLedgerDisplayText(selectedLedger);
          const balance =
            selectedLedger.available !== null
              ? getFormattedCurrencyAmount(selectedLedger.available, selectedLedger.currency)
              : null;
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                {displayText}
              </Typography>
              {balance && (
                <Typography variant="body2" sx={{ whiteSpace: "nowrap", marginLeft: "auto" }}>
                  {balance}
                </Typography>
              )}
            </Box>
          );
        }}
        sx={{
          padding: "5.5px 1.5px!important",
        }}
        {...props}
      >
        {loading ? (
          <MenuItem disabled>Loading...</MenuItem>
        ) : error ? (
          <MenuItem disabled>Error loading ledgers</MenuItem>
        ) : (
          filteredLedgers.map((ledger) => (
            <MenuItem
              key={ledger.ledgerId}
              value={ledger.ledgerId}
              sx={{
                fontSize: textSize || "inherit",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" noWrap>
                {getLedgerDisplayText(ledger)}
              </Typography>
              {ledger.available !== null && (
                <Typography variant="body2" sx={{ marginLeft: "auto", whiteSpace: "nowrap" }}>
                  {getFormattedCurrencyAmount(ledger.available, ledger.currency)}
                </Typography>
              )}
            </MenuItem>
          ))
        )}
      </Select>
      {isError && (
        <div
          style={{
            color: "#f44336",
            fontSize: "0.75rem",
            marginLeft: "14px",
            marginTop: "4px",
          }}
        >
          {meta.error}
        </div>
      )}
    </FormControl>
  );
};

LedgerDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  maxHeight: PropTypes.number,
  textSize: PropTypes.string,
  label: PropTypes.string.isRequired,
  setSelectedCurrency: PropTypes.func,
  setSelectedLedger: PropTypes.func,
  filterActiveLedgers: PropTypes.func,
  externalOnChange: PropTypes.func,
  defaultCurrency: PropTypes.string,
  props: PropTypes.object,
};

export default LedgerDropdown;
