import React, { useContext, useEffect, useState } from "react";
// material-ui
import { Alert, Box, Button, Divider, Grid, IconButton, Snackbar, Stack, Tooltip, Typography } from "@mui/material";
import CalendarTodayTwoToneIcon from "@mui/icons-material/CalendarTodayTwoTone";
// project imports
import SubCard from "ui-component/cards/SubCard";
import { gridSpacing2 } from "common/constant";
import { formatDateToMonthDayYear } from "../../common/getDate";
import { getFormattedCurrencyAmountWithName } from "store/getFormattedCurrencyAmount";
import { renderBankDetails } from "../../store/renderBankDetails";
import { useTheme } from "@mui/material/styles";
import DataGridStatusChip from "../datagrid/DataGridStatusChip";
import { downloadPDF } from "../../store/pdf-generator/downloadPDF";
import { RolesContext } from "../../store/context/RolesContext";
import CancelModal from "../extended/modal/CancelModal";
// assets
import { IconDownload } from "@tabler/icons-react";
import getTransactionDetails from "./getTransactionDetails";

const detailsIconSX = {
  width: 15,
  height: 15,
  verticalAlign: "text-top",
  mr: 0.5,
  mt: 0.25
};

const TransactionSummary = ({ selectedTransaction, refetchTransactions }) => {
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const theme = useTheme();
  const [transactionKey, setTransactionKey] = useState(Object.keys(selectedTransaction)[0]);
  const [transactionData, setTransactionData] = useState(selectedTransaction?.[transactionKey]);

  const isoDateString = transactionData.createdAt;
  const formattedDate = formatDateToMonthDayYear(isoDateString);
  const { roles } = useContext(RolesContext);

  const hasFxAmount = transactionData.fx && "amount" in transactionData.fx && transactionData.fx.amount !== null;
  const receiverAmount = hasFxAmount ? transactionData.fx.amount : transactionData.amount;
  const receiverCurrency = hasFxAmount ? transactionData.fx.currency : transactionData.currency;

  const userHasCancelRole = roles.includes("payment::cancel");
  const isBankTransaction = roles.includes("bank::payment");
  const rejectReasonAllowed = sessionStorage.getItem("displayRejectReason");

  const sxDivider = { borderColor: theme.palette.secondary.main };

  const handleCancelSubmission = () => {
    const updatedTransactionData = { ...transactionData, state: "canceled" };
    setOpenCancelModal(false);
    setToastOpen(true);
    refetchTransactions?.();
    setTransactionData(updatedTransactionData);
  };

  useEffect(() => {
    setTransactionKey(Object.keys(selectedTransaction)[0]);
    setTransactionData(selectedTransaction?.[transactionKey]);
  }, [selectedTransaction]);

  const isPdfDownloadAllowed = (transactionState, transactionType) =>
    ["pending", "sent", "complete"].includes(transactionState) &&
    !["collected", "received", "transfer"].includes(transactionType);

  return (
    <Box maxHeight="95vh" overflowY="auto">
      <Grid container spacing={gridSpacing2}>
        <Grid item xs={12}>
          <SubCard
            title={`Payment Reference: ${transactionData.reference}`}
            secondary={
              isPdfDownloadAllowed(transactionData.state, transactionKey) && (
                <>
                  <Tooltip title="Download A Copy">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        downloadPDF(selectedTransaction, isBankTransaction)
                      }
                    >
                      <IconDownload size={32} />
                    </IconButton>
                  </Tooltip>
                </>
              )
            }
          >
            <Grid container spacing={gridSpacing2}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      <CalendarTodayTwoToneIcon sx={detailsIconSX} />
                      Book Date: {formattedDate}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Stack spacing={3}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="subtitle1">
                          Payment status:
                        </Typography>
                        <DataGridStatusChip
                          state={transactionData.state}
                          label={rejectReasonAllowed === "true" && transactionData.rejectReason
                            ? transactionData.rejectReason
                            : null
                          }
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={sxDivider} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing2}>
                  <Grid item xs={12}>
                    <Stack spacing={2}>
                      <Typography variant="h4">Payment Details</Typography>
                      <Stack spacing={0}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1">ID: </Typography>
                          <Typography variant="body2">
                            {transactionData.id}
                          </Typography>
                        </Stack>
                        {getTransactionDetails(transactionKey, transactionData)}
                        {transactionData.receiver &&
                          transactionData.receiver.details && (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography variant="subtitle1">
                                {transactionData.receiver.details.name}{" "} receives:
                              </Typography>
                              <Typography variant="body2">
                                {getFormattedCurrencyAmountWithName(receiverAmount, receiverCurrency)}
                              </Typography>
                            </Stack>
                          )}
                        {hasFxAmount && "rate" in transactionData.fx && (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography variant="subtitle1">
                              FX Rate:
                            </Typography>
                            <Typography variant="body2">
                              {transactionData.fx.rate}
                            </Typography>
                          </Stack>
                        )}
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Typography variant="subtitle1">Purpose:</Typography>
                          <Typography variant="body2">
                            {transactionData.description}
                          </Typography>
                        </Stack>
                        {transactionData.addenda && (
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="subtitle1">
                                Note To Recipient:
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2">
                                {transactionData.addenda}
                              </Typography>
                            </Grid>
                          </Stack>
                        )}
                        {transactionData.notes &&
                          transactionData.notes.length > 0 && (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography variant="subtitle1">
                                  Internal Notes:
                                </Typography>
                              </Grid>
                              <Grid item>
                                {transactionData.notes.map(({ note }) => (
                                  <Typography variant="body2">
                                    {note}
                                  </Typography>
                                ))}
                              </Grid>
                            </Stack>
                          )}
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={sxDivider} />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={gridSpacing2}>
                  {transactionData.receiver?.details && (
                    <Grid item sm={6}>
                      {renderBankDetails(transactionData.receiver.details, transactionData.receiver.details.name)}
                    </Grid>
                  )}
                  <Grid item sm={6}>
                    <Stack spacing={2}>
                      <Typography variant="h4">
                        {transactionData.originator.details.name} Details
                      </Typography>
                      <Stack>
                        {transactionData.originator.accountNumber && (
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            <Typography variant="subtitle1">
                              Source Account:
                            </Typography>
                            <Typography variant="body2">
                              {transactionData.originator.accountNumber}
                            </Typography>
                          </Stack>
                        )}
                        {transactionData.originator.details.address && (
                          <>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Typography variant="subtitle1">Address:</Typography>
                              <Typography variant="body2">
                                {transactionData.originator.details.address.street1}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Typography variant="subtitle1">City:</Typography>
                              <Typography variant="body2">
                                {transactionData.originator.details.address.city}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Typography variant="subtitle1">Country:</Typography>
                              <Typography variant="body2">
                                {transactionData.originator.details.address.country}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Typography variant="subtitle1">State:</Typography>
                              <Typography variant="body2">
                                {transactionData.originator.details.address.regionCode}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Typography variant="subtitle1">Zip code:</Typography>
                              <Typography variant="body2">
                                {transactionData.originator.details.address.postalCode}
                              </Typography>
                            </Stack>
                          </>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {transactionData.state === "pending" && userHasCancelRole && (
              <Tooltip title="Cancel Transaction">
                <Button
                  onClick={() => setOpenCancelModal(true)}
                  color="error"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  Cancel Transaction
                </Button>
              </Tooltip>
            )}
          </SubCard>
        </Grid>
      </Grid>
      <CancelModal
        open={openCancelModal}
        onCancel={() => setOpenCancelModal(false)}
        onConfirm={handleCancelSubmission}
        transactionData={transactionData}
      />
      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={() => setToastOpen(false)}
      >
        <Alert
          onClose={() => setToastOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Transaction successfully canceled.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TransactionSummary;
