import React from "react";
import { Stack, Typography } from "@mui/material";
import { getFormattedCurrencyAmountWithName } from "store/getFormattedCurrencyAmount";

/**
 * Helper function to display transaction details.
 *
 * @param {string} transactionKey - The key representing the type of transaction ('transfer', 'collected', 'received', etc.).
 * @param {object} transactionData - The transaction data containing the details.
 * @return {JSX.Element} - JSX for the transaction details.
 */
const getTransactionDetails = (transactionKey, transactionData) => {
  switch (transactionKey) {
    case "transfer":
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="subtitle1">
            {transactionData.originator.details.name} transferred:
          </Typography>
          <Typography variant="body2">
            {getFormattedCurrencyAmountWithName(
              transactionData.amount,
              transactionData.currency
            )}
          </Typography>
        </Stack>
      );
    case "collected":
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="subtitle1">
            {transactionData.originator.details.name} collected:
          </Typography>
          <Typography variant="body2">
            {getFormattedCurrencyAmountWithName(
              transactionData.amount,
              transactionData.currency
            )}
          </Typography>
        </Stack>
      );
    case "received":
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="subtitle1">
            {transactionData.originator.details.name} received:
          </Typography>
          <Typography variant="body2">
            {getFormattedCurrencyAmountWithName(
              transactionData.amount,
              transactionData.currency
            )}
          </Typography>
        </Stack>
      );
    default:
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="subtitle1">
            {transactionData.originator.details.name} sends:
          </Typography>
          <Typography variant="body2">
            {getFormattedCurrencyAmountWithName(
              transactionData.amount,
              transactionData.currency
            )}
          </Typography>
        </Stack>
      );
  }
};

export default getTransactionDetails;