import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useAuthHeaders } from "../../auth/AuthHeaders";
import ConfigContext from "../../store/context/ConfigContext";
import { AuthContext } from "../../auth/AuthContext";
import {
  availableRoles,
  groupedRoles,
  roleMapping,
} from "../../auth/availableRoles";

import {
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const RoleSelectionModal = ({ open, onClose, user, actionType }) => {
  const { userSub } = useContext(AuthContext);
  const config = useContext(ConfigContext);
  const authHeaders = useAuthHeaders();
  const clientId = sessionStorage.getItem("clientId");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [initialRoles, setInitialRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const shouldUpdateSessionAndContext = user.reference === userSub;

  useEffect(() => {
    if (!userSub) {
      console.log("Waiting for userSub to be set...");
      return;
    }
    const initializeRoles = () => {
      const rolesFromUser = availableRoles.filter(
        (role) =>
          user &&
          user.roles.some(
            (userRole) =>
              userRole.role === roleMapping[role.value] ||
              userRole.role === role.value,
          ),
      );
      setInitialRoles(rolesFromUser.map((role) => role.value));
      setSelectedRoles(
        actionType === "grant" ? rolesFromUser.map((role) => role.value) : [],
      );
    };

    initializeRoles();
    setIsLoading(false);
  }, [user, actionType]);

  const handleRoleSelection = (role) => {
    if (
      (actionType === "grant" && initialRoles.includes(role)) ||
      (actionType === "revoke" && !initialRoles.includes(role))
    ) {
      return;
    }
    setSelectedRoles((prevRoles) =>
      prevRoles.includes(role)
        ? prevRoles.filter((r) => r !== role)
        : [...prevRoles, role],
    );
  };

  const handleToggleAllRoles = (roles) => {
    const selectableRoles = roles.filter(
      (role) => !isCheckboxDisabled(role.value),
    );
    const areAllSelectableRolesSelected = selectableRoles.every((role) =>
      selectedRoles.includes(role.value),
    );

    if (areAllSelectableRolesSelected) {
      // Unselect all selectable roles in this section
      setSelectedRoles((prevRoles) =>
        prevRoles.filter(
          (role) => !selectableRoles.map((r) => r.value).includes(role),
        ),
      );
    } else {
      // Select all selectable roles in this section
      const rolesToAdd = selectableRoles.filter(
        (role) => !selectedRoles.includes(role.value),
      );
      setSelectedRoles((prevRoles) => [
        ...prevRoles,
        ...rolesToAdd.map((role) => role.value),
      ]);
    }
  };

  const isCheckboxDisabled = (role) => {
    return (
      (actionType === "grant" && initialRoles.includes(role)) ||
      (actionType === "revoke" && !initialRoles.includes(role))
    );
  };

  const handleInviteWithRoles = async () => {
    setIsLoading(true);
    let allRequestsSuccessful = true;
    try {
      const rolesToProcess =
        actionType === "grant"
          ? selectedRoles.filter((role) => !initialRoles.includes(role))
          : selectedRoles;
      let updatedRoles = [...initialRoles];

      for (const roleId of rolesToProcess) {
        const data = {
          [actionType === "grant" ? "grantee" : "revokee"]: user.reference,
          realm: clientId,
          role: roleMapping[roleId] || roleId,
        };

        const grantOrRevokeUrl = `/login/v1/${actionType}`;

        const response = await axios.post(
          `${config.API_URL}${grantOrRevokeUrl}`,
          data,
          { headers: authHeaders },
        );
        if (
          response.data.error &&
          response.data.error.code === "PermissionDenied"
        ) {
          sessionStorage.clear();
          window.location.href = "/permission-denied";
          return;
        }

        if (
          response.status === 200 &&
          Object.keys(response.data).length === 0
        ) {
          if (actionType === "grant" && !updatedRoles.includes(roleId)) {
            updatedRoles.push(roleId);
          } else if (actionType === "revoke") {
            updatedRoles = updatedRoles.filter((role) => role !== roleId);
          }
        } else {
          allRequestsSuccessful = false;
          break;
        }
      }

      if (shouldUpdateSessionAndContext) {
        if (allRequestsSuccessful) {
          sessionStorage.clear();
          window.location.href = "/roles-updated";
        }
      }
      setTimeout(() => {
        setIsLoading(false);
        if (allRequestsSuccessful) {
          setSelectedRoles(updatedRoles);
          onClose();
        }
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/token-expiry";
      }
      console.error("Error processing role changes:", error);
    }
  };

  const renderCheckbox = (roleObj) => {
    const isChecked = selectedRoles.includes(roleObj.value);
    const isDisabled = isCheckboxDisabled(roleObj.value);

    const checkedIcon = <IndeterminateCheckBox style={{ color: "red" }} />;
    const icon = <CheckBoxOutlineBlank />;

    return (
      <Checkbox
        checked={isChecked}
        onChange={() => handleRoleSelection(roleObj.value)}
        disabled={isDisabled}
        icon={icon}
        checkedIcon={actionType === "revoke" ? checkedIcon : undefined}
      />
    );
  };

  const renderRoleSection = (sectionName, roles) => {
    const selectableRoles = roles.filter(
      (role) => !isCheckboxDisabled(role.value),
    );
    const areAllSelectableRolesSelected = selectableRoles.every((role) =>
      selectedRoles.includes(role.value),
    );

    return (
      <div key={sectionName} style={{ marginBottom: "1rem" }}>
        <Typography
          variant="h4"
          gutterBottom
          style={{ display: "flex", alignItems: "center" }}
        >
          {sectionName}
          <Button
            variant="text"
            size="small"
            onClick={() => handleToggleAllRoles(roles)}
            style={{ marginLeft: "auto" }}
            disabled={selectableRoles.length === 0} // Disable if no selectable roles
          >
            {areAllSelectableRolesSelected ? "Unselect All" : "Select All"}
          </Button>
        </Typography>
        {roles.map((role) => (
          <FormControlLabel
            key={role.value}
            control={renderCheckbox(role)}
            label={role.label}
          />
        ))}
        <Divider style={{ marginTop: "1rem" }} />
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          fontWeight: "bold",
          fontSize: "1.05rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {actionType === "grant"
          ? `Select Permissions to Add for ${user.username}`
          : `Select Permissions to Revoke for ${user.username}`}
        <IconButton
          onClick={onClose}
          size="small"
          style={{ marginLeft: "auto" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isLoading ? (
        <DialogContent
          style={{ display: "flex", justifyContent: "center", padding: "20px" }}
        >
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent>
          {Object.keys(groupedRoles).map((section) =>
            renderRoleSection(section, groupedRoles[section]),
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleInviteWithRoles}
            fullWidth
            sx={{ mt: 2 }}
          >
            {actionType === "grant"
              ? `Grant Selected Roles`
              : `Revoke Selected Roles`}
          </Button>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default RoleSelectionModal;
