import { useCallback, useContext, useState } from "react";
import axios from "axios";

// project imports
import { useAuthHeaders } from "../../auth/AuthHeaders";
import ConfigContext from "../context/ConfigContext";

//-------------------------------------TRANSACTIONS Data Hook --------------------------------------//
const useTransactionHistoryData = url => {
  const [data, setData] = useState({});

  const authHeaders = useAuthHeaders();
  const { API_URL } = useContext(ConfigContext);

  const extractTransactionData = transaction => {
    let type = Object.keys(transaction)[0];
    let data = transaction[type];

    let id, reference, estimatedAt, state, amount, account, currency, createdAt;

    switch (type) {
      case "debit":
      case "credit":
      case "transfer":
      case "transferOut":
      case "transferIn":
      case "collected":
      case "received":
        estimatedAt = data.estimatedAt;
        reference = data.reference;
        state = data.state;
        id = data.id;
        createdAt = data.created;
        account = data.account;

        if (data.fx && data.fx.amount && data.fx.currency) {
          amount = data.fx.amount;
          currency = data.fx.currency;
        } else {
          amount = data.amount;
          currency = data.currency;
        }
        break;

      default:
        return null;
    }

    return {
      type,
      reference,
      state,
      estimatedAt,
      amount,
      currency,
      id,
      createdAt,
      account
    };
  };

  const fetchData = useCallback(
    async (begin, end, ledgerId) => {
      try {
        // Corrected end date value as backend accepts non inclusive end dates
        const endDateValue = end.add(1, "day").startOf("day");
        const response = await axios.post(
          `${API_URL}${url}`,
          {
            clientId: sessionStorage.getItem("clientId"),
            request: {
              begin,
              end: endDateValue,
              ledgerId
            }
          },
          { headers: authHeaders }
        );

        const processedTransactions = (response.data.ledger?.transactions || []).map((tx, index) => {
          let extractedData = extractTransactionData(tx);
          extractedData.id = index;
          return extractedData;
        });

        setData(processedTransactions.reverse());
      } catch (error) {
        if (error.response && error.response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/token-expiry";
        } else {
          console.error("Error fetching data:", error);
        }
      }
    },
    [url]
  );

  return {
    data,
    fetchData
  };
};

export default useTransactionHistoryData;
